<template>
    <div class="page">
        <div class="wrapper">
            <div class="re-title">{{$t('Deposit')}}</div>
            <div class="re-btn">
                <div class="t-btn">
                    <img src="../../assets/pictures/reusdt/USDT.png"/>
                    <span>{{$t('Tether')}}</span>
                    <van-icon name="arrow" class="t-btn-i"/>
                </div>
                <div class="t-btn">
                    <img src="../../assets/pictures/reusdt/tron.png"/>
                    <span>{{$t('TRC20')}}</span>
                </div>
            </div>
            <div class="re-title">{{$t('Your USDT deposit address')}}</div>
            <div class="re-address">
                <span>{{data.a}}</span>
                <img class="copy-w" src="../../assets/pictures/reusdt/copy@1x.png" data-clipboard-action="copy"
                     :data-clipboard-text="data.a" @click="copy('.copy-w')"/>
            </div>
            <div class="re-content">
                <div class="c-top">
                    <div class="c-tip"
                         v-html="$t('请向上方指定TRC20链钱包地址打款，选错链路或者汇错钱包地址将无法到账，同时也无法找回，完成交易后请到充值订单列表关注订单状态，预计时间3-10分钟。')"></div>
                    <div class="c-qr">
                        <div class="c-time">
                            <img src="../../assets/pictures/reusdt/exptime@2x.png"/>
                            <span>{{$t('Expiration Time')}} {{timeout_str}}</span>
                        </div>
                        <div class="c-qrcode">
                            <div ref="shareQrcode" id="shareQrcode"></div>
                        </div>
                        <div class="c-money">
                            <div>{{$t('USDT')}}</div>
                            <div>{{data.m}}</div>
                        </div>
                    </div>
                </div>
                <div class="c-footer">
                    <van-button class="f-btn" @click="openService">
                        <div class="c-f-btn">
                            <img src="../../assets/pictures/reusdt/kf@2x.png"/>
                            <span>{{$t('Contact Customer Service')}}</span>
                        </div>
                    </van-button>
                </div>
            </div>
            <div class="re-title">{{$t('The Official Partner of')}}</div>
            <div class="re-partner">
                <template v-for="(item, i) in list">
                    <a v-if="i < 14" :href="item.homepage" target="_blank" :key="i">
                        <img :src="item.image">
                    </a>
                </template>
            </div>
        </div>
        <van-popup class="usdt-show-pop" v-model="show" position="center"
                   :close-on-click-overlay="false" closeable>
            <div class="u-p-content">
                <div class="u-p-top">
                    <img src="../../assets/pictures/reusdt/ok@2x.png"/>
                    <span>{{$t('复制成功')}}</span>
                    <div class="u-p-address">{{data.a}}</div>
                </div>
                <div class="u-p-time">
                    <img src="../../assets/pictures/reusdt/to1.png"/>
                    <span>{{timeout_str}}</span>
                </div>
                <div class="u-p-divider"></div>
                <!--                <div class="u-p-tip">{{$t('连接钱包，充值USDT')}}</div>-->
                <div class="u-p-search">
                    <van-field :placeholder="$t('Search Wallet Name')" v-model="keyword" autofocus="autofocus">
                        <template #button>
                            <van-icon name="search" @click="searchWc(1)"/>
                        </template>
                    </van-field>
                </div>
                <div class="u-p-address-list">
                    <template v-if="loading == false">
                        <template v-for="(item, i) in search_list.list">
                            <a v-if="i < 10" :href="item.homepage" target="_blank" :key="i">
                                <img :src="item.image">
                                <span>{{item.name}}</span>
                            </a>
                        </template>
                    </template>
                    <template v-else>
                        <div class="u-p-loading">
                            <van-loading size="50"/>
                        </div>
                    </template>
                </div>
                <div class="u-p-page">
                    <van-button :disabled="this.page == 1" @click="pageChange(-1)">{{$t('Previos')}}</van-button>
                    <van-button :disabled="this.page == (Math.ceil(this.search_list.count / 8))" @click="pageChange(1)">
                        {{$t('Next')}}
                    </van-button>
                </div>
            </div>
            <div class="u-p-no-address">{{$t('还没有加密钱包？')}}</div>
            <div class="u-p-footer">
                <van-button @click="openService"><span class="u-p-f-text">{{$t('了解如何连接')}}</span></van-button>
            </div>
        </van-popup>
    </div>
</template>
<script>
    import Clipboard from "clipboard";
    import QRCode from "qrcodejs2";
    import helper from "../../utils/helper";
    export default {
        components: {},
        data() {
            return {
                show: false,
                page: 1,
                type: 'wallet',
                keyword: '',
                count: 0,
                list: [],
                search_list: {list: [], count: 0},
                data: {
                    a: '',
                    m: ''
                },
                timeout: 1800,
                timeout_str: '30:00',
                d_type: 'TRC20',
                qecode: '',
                loading: false,
            }
        },
        methods: {
            pageChange(val) {
                this.page = this.page + val;
                this.searchWc(this.page);
            },
            searchWc(page) {
                this.loading = true;
                let url = '/get_wallet_connect?page=' + page + '&type=wallet';
                let keyword = this.keyword.trim();
                if (keyword !== '') {
                    url = url + '&keyword=' + keyword;
                }
                let that = this;
                this.$axios.get(url).then((res) => {
                    if (res.status == 200) {
                        that.search_list = res.data.data;
                        that.loading = false;
                    }
                });
            },
            partnerList() {
                let url = '/get_wallet_connect?page=1&size=14';
                let that = this;
                this.$axios.get(url).then((res) => {
                    if (res.status == 200) {
                        that.list = res.data.data.list;
                    }
                });
            },
            openService() {
                console.log(navigator.language);
                if (window.self !== window.top) {
                    window.parent._vue.openService();
                } else {
                    window._vue.openService();
                }
            },
            timerInit(s) {
                this.timeout = s;
                let timer = setInterval(() => {
                    if (this.timeout <= 0) {
                        clearInterval(timer);
                        localStorage.removeItem("u_last_timer");
                        return;
                    }
                    let ii = Math.floor(this.timeout / 60 % 60);
                    let ss = Math.floor(this.timeout % 60);
                    if (ii < 10) {
                        ii = '0' + ii;
                    }
                    if (ss < 10) {
                        ss = '0' + ss;
                    }
                    this.timeout_str = ii + ':' + ss;
                    this.timeout -= 1;
                    localStorage.setItem("u_last_timer", this.timeout);
                }, 1000);
            },
            copy(val) {
                let clipboard = new Clipboard(val);
                let that = this;
                clipboard.on("success", (e) => {
                    that.show = true;
                    // 释放内存
                    clipboard.destroy();
                });
                clipboard.on("error", (e) => {
                    // 不支持复制
                    this.$toast.fail(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
            genQrcode() {
                let url = this.data.a;
                if (this.qecode) {
                    this.qecode.clear();
                    this.qecode.makeCode(url);
                    this.share_url = url;
                    return;
                }
                this.qecode = new QRCode("shareQrcode", {
                    text: url,
                    typeNumber: 17,
                    width: 128,
                    height: 128,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
        },
        mounted() {
            if (this.$route.query.sign !== undefined) {
                let sign = this.$route.query.sign;
                let json_str = atob(helper.decrypt(sign));
                this.data = JSON.parse(json_str);
                this.genQrcode();
            }
            this.timerInit(this.timeout);
            this.partnerList();
            this.searchWc(1);
        }
    }
</script>
<style scoped>
    body, html {
        background-color: unset;
    }
    div, span {
        font-family: Bahnschrift, PingFangSC-Regular, PingFang SC;
        font-size: 14px;
    }
    .wrapper {
        margin-top: 10px;
    }
    .re-title {
        margin: 15px auto;
        font-size: 18px;
    }
    .re-btn {
        display: flex;
        justify-content: center;
    }
    .t-btn {
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        background-color: #F3F3F3;
        width: 49%;
        height: 40px;
        border-radius: 8px;
    }
    .t-btn-i {
        color: #45a0a3;
        position: absolute;
        right: 10px;
        font-size: 20px;
        font-weight: bold;
    }
    .t-btn:last-child {
        margin-left: 2%;
        background-color: #FFD300;
    }
    .t-btn img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
    }
    .re-btn {
        display: flex;
        align-items: center;
    }
    .re-address {
        font-size: 12px;
        display: flex;
        justify-items: center;
        align-items: center;
        justify-content: space-between;
        background-color: #F1F0ED;
        border-radius: 8px;
        height: 55px;
        padding: 0 15px;
    }
    .re-address img {
        width: 30px;
    }
    .re-content {
        color: #787878;
        margin-top: 10px;
        border-radius: 8px;
        padding: 10px;
        background-color: #F1F0ED;
    }
    .re-content .c-tip {
        font-size: 12px;
        zoom: 0.95;
        line-height: 23px;
        padding-right: 5px;
        padding-top: 10px;
    }
    .c-top {
        display: flex;
        justify-content: space-between;
    }
    .c-top > div:first-child {
        width: 50%;
    }
    .c-qr {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 50%;
        background-color: #ffffff;
        border-radius: 10px;
        /*padding: 10px;*/
    }
    .c-time {
        width: 100%;
        display: flex;
        /*align-items: ;*/
        color: #FF9429;
        /*height: 40px;*/
        padding: 10px 10px 5px 10px;
    }
    .c-time img {
        width: 14px;
        height: 20px;
        margin-right: 5px;
        padding-bottom: 5px;
    }
    .c-qrcode {
        padding: 20px;
        border-bottom: 0.5px dashed #EFEFEF;
        border-top: 0.5px dashed #EFEFEF;
    }
    .c-footer {
        margin-top: 10px;
    }
    .c-money {
        padding: 10px;
    }
    .f-btn {
        border-radius: 10px;
        width: 100%;
        height: 55px;
    }
    .c-f-btn {
        width: 100%;
        display: flex;
        color: #FF8F00;
        justify-content: center;
        align-items: center;
    }
    .c-f-btn span {
        font-size: 22px;
        /*font-weight: bold;*/
    }
    .c-f-btn img {
        width: 30px;
        margin-right: 10px;
    }
    .re-partner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .re-partner a {
        width: 14.2%;
        padding: 5px;
    }
    .re-partner a > img {
        border-radius: 50%;
        box-shadow: 0 0 4px 2px #cccccc;
    }
    .usdt-show-pop {
        width: 90%;
        /*height: 720px;*/
        border-radius: 15px;
        background-color: #FFD300;
        padding: 35px 10px 0px 10px;
        top: 45%;
    }
    .u-p-content {
        background-color: #ffffff;
        border-radius: 15px;
        width: 100%;
        /*height: 380px;*/
        /*padding: 10px;*/
    }
    .u-p-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .u-p-top img {
        width: 45px;
        margin: 10px auto;
    }
    .u-p-top .u-p-address {
        font-size: 12px;
        margin: 15px auto auto auto;
        background-color: #F1F0ED;
        width: 95%;
        text-align: center;
        line-height: 35px;
        border-radius: 5px;
    }
    .u-p-time{
        margin: 10px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 20px;
    }
    .u-p-time img{
        height: 100%;
        margin-right: 5px;
    }
    .u-p-time span{
        color: #FF9429;
        font-size: 16px;
        min-width: 38px;
        padding-top: 2px;
    }
    .u-p-divider {
        width: 100%;
        height: 1px;
        background: linear-gradient(#fff, #fff) padding-box, repeating-linear-gradient(-90deg, #000 0, #000 5px, #fff 0, #fff 10px);
        transform: scaleY(0.2);
    }
    .u-p-tip {
        margin-top: 15px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
    }
    .u-p-address-list {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        min-height: 180px;
    }
    .u-p-address-list a {
        padding: 5px;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .u-p-address-list a > img {
        width: 50%;
        margin-bottom: 10px;
        border-radius: 50%;
        box-shadow: 0 0 4px 2px #cccccc;
    }
    .u-p-address-list a > span {
        font-size: 12px;
        zoom: 0.8;
        height: 28px;
        text-align: center;
    }
    .u-p-loading{
        width: 100%;
        min-height: 150px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    .u-p-no-address {
        margin: 10px auto;
        text-align: center;
        color: #FFFEFE;
        letter-spacing: 1px;
    }
    .u-p-footer {
        margin-bottom: 15px;
    }
    .u-p-footer button {
        width: 100%;
        border-radius: 10px;
        background: linear-gradient(#FFF8B7, #FFE879);
        box-shadow: 0px 1px 1px 1px #EFB400;
    }
    .u-p-f-text {
        font-weight: bold;
        font-size: 16px;
    }
    .u-p-search {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
    }
    .u-p-search .van-cell {
        width: 95%;
        border-radius: 10px;
        background-color: #F1F0ED;
        padding: 0 0 0 20px;
    }
    .u-p-search .van-icon-search {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-size: 22px;
        background-color: #cccccc;
        width: 50px;
        height: 40px;
    }
    .u-p-page {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
    }
    .u-p-page .van-button {
        height: 28px;
        border-radius: 5px;
        margin: auto 10px;
    }
</style>
<style>
    .usdt-show-pop .van-popup__close-icon {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        background-color: #ffffff;
        border-radius: 50%;
        padding: 5px;
    }
    .usdt-show-pop .van-popup__close-icon--top-right {
        top: 5px;
        right: 5px;
    }
</style>